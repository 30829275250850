import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SidebarMenuService {
  private sbjSideBarMenu = new Subject<boolean>();

  saveSideBarMenu(value) {
    this.sbjSideBarMenu.next(value);
  }

  getSideBarMenu(): Observable<boolean> {
    return this.sbjSideBarMenu.asObservable();
  }
}
