<nb-layout>
    <nb-layout-header fixed>
        <ngx-header></ngx-header>
    </nb-layout-header>

    <nb-sidebar class="menu-sidebar" tag="menu-sidebar">
        <div class="logo-wrapper">
            <img class="main-logo" src="/assets/images/BIA Systems v1.svg" />
        </div>
        <ng-content select="nb-menu" tag="menu-side"></ng-content>
    </nb-sidebar>

    <nb-layout-column class="p-0">
        <div
            class="content-wrapper"
            [class.tab-wrapper]="subheadermode!=='account-view'"
            [class.acc-wrapper]="subheadermode==='account-view'"
        >
            <div class="tab-content scrollable-container">
                <nb-route-tabset [tabs]="tabs"></nb-route-tabset>
            </div>
        </div>
    </nb-layout-column>
</nb-layout>
