import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NbDialogService, NbMenuService, NbSidebarService } from '@nebular/theme';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';

import { SidebarMenuService } from '../../../shared/services/sidebar-menu.service';
import { SupportDialogComponent } from '../../../pages/support-dialog/support-dialog.component';

@Component({
  selector: 'ngx-one-column-layout',
  styleUrls: ['./one-column.layout.scss'],
  templateUrl: './one-column.layout.html',
})
export class OneColumnLayoutComponent implements OnInit, OnDestroy {
  @ViewChild('tabslist') tabslist_element: ElementRef;
  public fullname: string;
  public sideMenuExpanded: boolean = true;

  // TODO: make it modal-like instead of using subheadermode?
  public subheadermode: string = 'account-view';
  public tabs = [];
  menu_click_subscription: Subscription;

  constructor(
    private sidebarService: NbSidebarService,
    private router: Router,
    private nbMenuService: NbMenuService,
    private sidebarMenuService: SidebarMenuService,
    private dialogService: NbDialogService,
  ) {
    this.checkRouterUrl(this.router.url);
  }

  ngOnInit() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.checkRouterUrl(event.url);
        this.setSidebar(event.url);
      }
    });

    this.nbMenuService.onSubmenuToggle().subscribe((event) => {
      if (!this.sideMenuExpanded) {
        this.sideMenuExpanded = true;
        this.sidebarMenuService.saveSideBarMenu(this.sideMenuExpanded);
      }
    });

    this.menu_click_subscription = this.nbMenuService
      .onItemClick()
      .pipe(filter(({ tag }) => !tag || !tag.split('-').length || !tag.split('-')[0].includes('ContextMenu')))
      .subscribe((event) => {
        if (event.item.title === 'Support') {
          this.dialogService.open(SupportDialogComponent).onClose.subscribe((_res) => {
            // what to do after closing the support dialogue?
          });
        } else if (!(event.item.title === 'Login & Security' || event.item.title === 'Personal Info')) {
          this.subheadermode = 'tabs-view';
          this.router.navigate([event.item.link]);
        } else {
          this.subheadermode = 'account-view';
        }
      });

    this.sidebarService.onToggle().subscribe((res) => {
      this.sideMenuExpanded = !this.sideMenuExpanded;
      this.sidebarMenuService.saveSideBarMenu(this.sideMenuExpanded);
    });

    this.setSidebar(this.router.url);
  }
  ngOnDestroy() {
    this.menu_click_subscription.unsubscribe();
  }

  checkRouterUrl(url) {
    if (url.startsWith('/account')) {
      this.subheadermode = 'account-view';
    } else if (url.startsWith('smart-flow/flow/')) {
      this.subheadermode = 'flow-view';
    } else {
      this.subheadermode = 'tabs-view';
    }
  }

  setSidebar(url: string) {
    if (url.startsWith('/smart-flow/flow/')) {
      // in a set time out since initially UI needs to be
      // rendered and initial state must be set or the state below would just get
      // overwritten.
      setTimeout(() => {
        this.sidebarService.compact('menu-sidebar');
        this.sideMenuExpanded = false;
        this.sidebarMenuService.saveSideBarMenu(this.sideMenuExpanded);
      });
    }
  }
}
