import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';

@Injectable()
export class LoadingService {
  private _loadingInProcess$ = new BehaviorSubject<boolean>(false);
  private _counter = 0;
  private _timerId;

  get loadingStatus() {
    return this._loadingInProcess$.asObservable();
  }

  startLoading() {
    this._counter++;
    this._loadingInProcess$.next(true);

    this._timerId = setTimeout(() => {
      this._counter = 0;
      this.stopLoading();
    }, 120000);
  }

  stopLoading() {
    if (this._counter > 0) {
      this._counter--;
    }
    if (this._counter === 0) {
      clearTimeout(this._timerId);
      this._loadingInProcess$.next(false);
    }
  }
}
