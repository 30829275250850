<div class="header-container">
    <div class="logo-container">
        <a (click)="toggleSidebar()" class="sidebar-toggle" href="#">
            <nb-icon icon="menu-2-outline"></nb-icon>
        </a>
    </div>
</div>

<div class="header-container">
    <div id="page-heading"></div>
</div>

<div class="header-container">
    <nb-actions size="small">
        <nb-action class="user-action">
            <nb-user [name]="userName" nbContextMenuTag="my-context-menu" [nbContextMenu]="basicUserMenu" onlyPicture>
            </nb-user>
            <nb-icon
                icon="arrow-ios-downward-outline"
                class="arrow-down-icon"
                (click)="open()"
                nbContextMenuTag="my-context-menu"
                [nbContextMenu]="basicUserMenu"
            ></nb-icon>
        </nb-action>
    </nb-actions>
</div>
