import { Component } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import packageInfo from '../../../../package.json';

@Component({
  selector: 'ngx-support-dialog',
  templateUrl: './support-dialog.component.html',
  styleUrls: ['./support-dialog.component.scss'],
})
export class SupportDialogComponent {
  appVersion = packageInfo.version;
  contact_emails = ['techsupport@bia-systems.tech'];

  constructor(protected dialogRef: NbDialogRef<SupportDialogComponent>) {}

  closePopup() {
    this.dialogRef.close();
  }
}
