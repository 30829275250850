import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { NbContextMenuDirective, NbMenuItem, NbMenuService, NbSidebarService } from '@nebular/theme';
import { Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { PermissionsEnum } from '../../../auth/models/permissions.model';
import { AuthService } from '../../../auth/services/auth.service';

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {
  @ViewChild(NbContextMenuDirective, { static: false }) contextMenu: NbContextMenuDirective;

  private subscription: Subscription;
  private subscriptionUserInfo: Subscription;

  userName: string;
  basicUserMenu: NbMenuItem[] = [
    {
      title: 'Log out',
      icon: 'log-out-outline',
    },
  ];
  userMenu: NbMenuItem[] = [
    {
      title: 'Login & Security',
      icon: 'lock-outline',
      data: {
        permissions: [PermissionsEnum.CHANGE_PASSWORD],
      },
    },
  ];

  constructor(
    private sidebarService: NbSidebarService,
    private menuService: NbMenuService,
    private router: Router,
    private authService: AuthService,
  ) {}

  ngOnInit() {
    this.userName = this.authService.userInfo?.fullName?.split(' ')[0];

    // filter menu
    this.basicUserMenu = [
      ...this.userMenu.filter((menuItem) => this.authService.checkPermissions(menuItem.data.permissions)),
      ...this.basicUserMenu,
    ];

    this.menuService
      .onItemClick()
      .pipe(
        filter(({ tag }) => tag === 'my-context-menu'),
        map(({ item: { title } }) => title),
      )
      .subscribe((title) => {
        switch (title) {
          case 'Log out':
            this.logout();
            break;
          case 'Login & Security':
            this.router.navigate(['/account']);
            break;
        }
      });
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
    this.subscriptionUserInfo?.unsubscribe();
  }

  open() {
    this.contextMenu.show();
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    return false;
  }

  logout() {
    this.authService.logout().subscribe((res) => {
      window.location.href = '/auth/login';
    });
  }
}
