<nb-card class="main-wrapper">
    <nb-card-header>
        <div class="close-btn" (click)="closePopup()">
            <i class="far fa-times-circle"></i>
        </div>
        <div>Get in touch</div>
    </nb-card-header>
    <nb-card-body>
      <div class="emails-wrapper">
        <div class="email-icon">
            <i class="far fa-envelope"></i>
        </div>
        <div class="emails">
            <div *ngFor="let email of contact_emails">
                <a
                    href="mailto:{{email}}"
                    target="_blank">{{email}}</a>
            </div>
        </div>
      </div>
      <div class="version"><small>v{{appVersion}}</small></div>
    </nb-card-body>
</nb-card>
